import axios from "axios";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { hashSync } from "bcryptjs";

export default function Reset() {
  const [params, setParams] = useSearchParams();
  const { register, handleSubmit, formState } = useForm<ResetForm>({
    defaultValues: { password: "", password_confirmation: "" },
  });
  const onSubmit = (payload: ResetForm) => {
    axios.get(`/account/api/password/reset/get/?${params}`).then(res => {
      axios.post(`/account/api/password/reset/?${params}`, {
        ...payload, password_confirmation: hashSync(
          payload.password + res.data, "$2a$12$yK7nj5qtSMHjNW.92Y4Zt."
        )
      })
        .then(() => window.location.href = '/account/signin');
    });
  };
  return (
    <div className="main-card-signin d-md-flex">
      <div className="wd-100p">
        <div className="main-signin-header">
          <div className="">
            <h2>Welcome back !</h2>
            <h4 className="text-left">Reset Your Password</h4>
            <form
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group text-left">
                <label>New Password</label>
                <input
                  {...register('password', {
                    minLength: 9,
                    required: true,
                    validate: (value, formValues) => [/[0-9]{1,}/, /[A-Z]{1,}/, /[a-z]{1,}/, /[()\[\]{}|`~!@#$%^&*_\-+=;:'",<>./?]/].every(test => test.test(value))
                  })}
                  className="form-control"
                  placeholder="Enter your password"
                  name="password"
                  id="password"
                  type="password"
                />
              </div>
              <div className="form-group text-left">
                <label>Confirm Password</label>
                <input
                  {...register('password_confirmation', {required: true, validate: (value, formValues) => value === formValues.password})}
                  className="form-control"
                  placeholder="Enter your password"
                  name="password_confirmation"
                  id="password_confirmation"
                  type="password"
                />
              </div>
              <button
                className="btn ripple btn-main-primary btn-block"
                type="submit"
                disabled={!formState.isValid}
              >
                Reset Password
              </button>
              <br />
              <div className="card text-center">
                <div className="card-body text-left">
                  <p className="py-0 mt-1 mb-1">
                    Requirements for the password:
                  </p>
                  <p className="py-0 my-0">
                    <small>must contain at least 9 caracters.</small>
                  </p>
                  <p className="py-0 my-0">
                    <small>must contain at least 1 digit.</small>
                  </p>
                  <p className="py-0 my-0">
                    <small>must contain at least 1 uppercase letter.</small>
                  </p>
                  <p className="py-0 my-0">
                    <small>must contain at least 1 lowercase.</small>
                  </p>
                  <p className="py-0 my-0">
                    <small>must contain at least 1 special character.</small>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="main-signup-footer mg-t-20">
          <p>
            Already have an account?
            <a href="/account/signin">Sign In</a>
          </p>
        </div>
      </div>
    </div>
  );
}

interface ResetForm {
  password: string;
  password_confirmation: string;
}
