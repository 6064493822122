import { TableBody, TableCell, TableRow } from "@mui/material";
import { DashboardTableResponse, TableResponse } from "./sensor-table";
import SensorTableData from "./sensor-table-data";
import {useSelector} from "react-redux";
import {RootState} from "@src/context/store";

interface Props{
  data: DashboardTableResponse;
  floors: string[];
  zones: string[];
}

export default function SensorTableBody({data, floors, zones}: Props) {
  const displaying = useSelector((state: RootState) => state.dashboard.table.displaying);
  
  const getItems = (data_: DashboardTableResponse, floor: string, zone: string): TableResponse[] => {
    const key = `${floor} - ${zone}`;
    return data_[key] ? data_[key].map((item: any) => item) : [];
  };


  return (
    <TableBody>
      {
        floors.map((floor: string, index: number) => {
          return (
            <TableRow key={floor+index}>
              <TableCell>{floor}</TableCell>
              {zones.map((zone: string, index: number) => (
                <TableCell align="center" key={zone+floor+index}  style={{border: "1px solid rgba(224, 224, 224, 1)"}}>
                  {
                    getItems(data, floor, zone)
                      //TODO: Remove when sensor type if ok
                      .filter(item => {
                          if (displaying == "co2") {
                            return (item?.current?.co2 || item?.previous?.co2)
                          }
                          return true
                      })
                      .map((item, index) => {
                        const {ts} = item.current;
                        return (
                            <SensorTableData
                              key={index+floor+zone+ts}
                              current={item.current}
                              previous={item.previous}
                              label={item.label}
                              deveui={item.deveui}
                              zone={zone}
                              etage={floor}
                            />
                        )
                      })
                  }
                </TableCell>
              ))}
            </TableRow>
          )
        })
      }

    </TableBody>
  );
}