interface Props {
    height?: string;
    width?: string;
    text?: string;
}
export default function Loading({height, text}: Props) {
    const style = {height: height ?? "auto"}
    return (
        <div className="d-flex flex-column align-items-center" style={style}>
            <div id="spinner-upload" className="spinner-border spinner text-center" role="status">
                <span className="sr-only"></span>
            </div>
            <span>{ text ?? gettext('Chargement en cours ...')}</span>
        </div>
    )
}

export function Spinner({height, width}: Props) {
    const style = {height: height ?? "auto", width: width ?? "auto"}
    return (
        <span id="spinner-upload" className="spinner-border spinner text-center" role="status" style={style}>
            <span className="sr-only"></span>
        </span>
    )
}