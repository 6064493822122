import {
  table as storeTable,
  setTableError as storeError,
} from "@src/context/reducers/dashboard";
import { RootState } from "@src/context/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableFilter from "../filters/table-filter";
import ToggleData from "../toggle-data";
import SensorTable from "./sensor-table";
import {updateURL} from "@src/common/utils";


interface TableData {
  ts: number;
  temperature: number;
  co2: number;
  label: string;
}
interface TableResponse {
  current: TableData;
  previous: TableData;
}

export interface DashboardTableResponse {
  [key: string]: TableResponse[];
}


export default function SensorTableTab() {
  const selectedEquipments = useSelector((state: RootState) => state.dashboard.selected.equipments);
  const store = useSelector((state: RootState) => state.dashboard.table);

  const dispatch = useDispatch();
  const dispatchTable = (data: any) => dispatch(storeTable(
    Object.assign({}, store, data))
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('view', "table");
    updateURL(searchParams);
  }, []);
  
  useEffect(() => {
    const equipments = [];
    const types: string[] = [];
    
    for (const equipment of selectedEquipments) {
      const {zone, etage, type} = equipment.gui_informations;
      if (!zone || !etage) {
        continue;
      }
      equipments.push(equipment);
      if (type && !types.find(t => t?.toLocaleLowerCase() === type?.toLocaleLowerCase())) {
        types.push(type);
      }
    }
    if (!equipments.length) {
      //TODO: Check why store does not update on without an event
      setTimeout(() => {
        dispatch(storeError(
            "Les étages et/ou les zones des équipements sont mal définies."
        ))
      }, 0);
    }else {
      setTimeout(() => {dispatch(storeError(""))}, 0);
    }
    dispatchTable({equipments, types});
  }, [selectedEquipments]);

  return (
    <>
      <ToggleData />
      <TableFilter /> 
      <SensorTable />
      <hr/>
    </>
  );
}