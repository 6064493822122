import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Mode } from "../common";
import {mode as storeMode} from "../context/reducers/metrology"
import Filters from "./components/filters/filters";

import '../scss/metrology/index.scss'
import Comfort from "./components/comfort";
import Energy from "./components/energy";

export const remoteTemperatureDeveui = "deveui_meteo_temperature"

export function Metrology({mode}: Props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(storeMode(mode))
  }, [mode])

  return (
    <div className="container-fluid">
      <div className="row row-sm">
        <div className="col col-md-3 ml-0 pl-0">
          <Filters/>
        </div>
        <div className="col col-md-9 mr-0 pr-0">
          {
            mode === Mode.COMFORT && <Comfort/>
          }
          {
            mode === Mode.ENERGY && <Energy/>
          }
          </div>
      </div>
    </div>
  );
}

interface Props {
  mode: Mode;
}
