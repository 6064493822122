import moment from 'moment';

var notify_badge_class = 'live_notify_badge';
var notify_menu_class;
var notify_api_url = '/inbox/notifications/api/unread_list/';
var notify_fetch_count = 5;
var notify_unread_url;
var notify_mark_all_unread_url;
var notify_refresh_period = 15000;
// Set notify_mark_as_read to true to mark notifications as read when fetched
var notify_mark_as_read = false;
var consecutive_misfires = 0;
var registered_functions = [];

function fill_notification_badge(data) {
    $('.live_notify_badge').text('');
    $('.live_notify_badge').text(data.unread_count)
}

function fill_notification_list(data) {
    let menus = document.getElementsByClassName('live_notify_list');
    if (menus) {
        var messages = data.unread_list.map(function (item) {
            return ' <div><a class="d-flex p-3 border-bottom" href="#">' +
                '<div class="notifyimg bg-pink" style="height: 40px; width: 40px;"> <i class="la la-file-alt text-white"></i> </div> <div class="ml-3"> ' +
                '<h5 class="notification-label mb-1">' + item.verb + '</h5> <div class="notification-subtext">' + moment(item.timestamp).format("DD-MM-YYYY HH:mm:ss") + '</div>' +
                '</div><div class="ml-auto"> <i class="las la-angle-right text-right text-muted"></i></div>' +
                '</a></div>';
        }).join('')

        for (var i = 0; i < menus.length; i++) {
            menus[i].innerHTML = messages;
        }
    }
}

// function register_notifier(func) {
//     registered_functions.push(func);
// }

function fetch_api_data() {
    // only fetch data if a function is setup
    var r = new XMLHttpRequest();
    var params = '?max=' + notify_fetch_count;

    if (notify_mark_as_read) {
        params += '&mark_as_read=true';
    }

    r.addEventListener('readystatechange', function (event) {
        if (this.readyState === 4) {
            if (this.status === 200) {
                consecutive_misfires = 0;
                var data = JSON.parse(r.responseText);
                fill_notification_badge(data);
                fill_notification_list(data);
            } else {
                consecutive_misfires++;
            }
        }
    });
    r.open("GET", notify_api_url + params, true);
    r.send();
    if (consecutive_misfires < 10) {
        setTimeout(fetch_api_data, notify_refresh_period);
    } else {
        var badges = document.getElementsByClassName(notify_badge_class);
        if (badges) {
            for (var i = 0; i < badges.length; i++) {
                badges[i].innerHTML = "!";
                badges[i].title = "Connection lost!"
            }
        }
    }
}

setTimeout(fetch_api_data, 1000);