import 'popper.js'
import PerfectScrollbar from 'perfect-scrollbar';

"use strict";

var slideMenu = $('.side-menu');
export const initSideMenu = () => {
  // Toggle Sidebar
  $(document).on('click', '[data-toggle="sidebar"]', function (event) {
    event.preventDefault();
    $('.app').toggleClass('sidenav-toggled');
  });
  
  $(".app-sidebar").hover(function () {
    if ($('body').hasClass('sidenav-toggled')) {
      $('body').addClass('sidenav-toggled-open');
    }
  }, function () {
    if ($('body').hasClass('sidenav-toggled')) {
      $('body').removeClass('sidenav-toggled-open');
    }
  });
  
  
  // Activate sidebar slide toggle
  $("[data-toggle='slide']").on('click', function (e) {
    var $this = $(this);
    var checkElement = $this.next();
    var animationSpeed = 300,
      slideMenuSelector = '.slide-menu';
    if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
      checkElement.slideUp(animationSpeed, function () {
        checkElement.removeClass('open');
      });
      checkElement.parent("li").removeClass("is-expanded");
    }
    else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
      var parent = $this.parents('ul').first();
      var ul = parent.find('ul:visible').slideUp(animationSpeed);
      ul.removeClass('open');
      var parent_li = $this.parent("li");
      checkElement.slideDown(animationSpeed, function () {
        checkElement.addClass('open');
        parent.find('li.is-expanded').removeClass('is-expanded');
        parent_li.addClass('is-expanded');
      });
    }
    if (checkElement.is(slideMenuSelector)) {
      e.preventDefault();
    }
  });
  
  // Activate sidebar slide toggle
  $("[data-toggle='sub-slide']").on('click', function (e) {
    var $this = $(this);
    var checkElement = $this.next();
    var animationSpeed = 300,
      slideMenuSelector = '.sub-slide-menu';
    if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
      checkElement.slideUp(animationSpeed, function () {
        checkElement.removeClass('open');
      });
      checkElement.parent("li").removeClass("is-expanded");
    }
    else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
      var parent = $this.parents('ul').first();
      var ul = parent.find('ul:visible').slideUp(animationSpeed);
      ul.removeClass('open');
      var parent_li = $this.parent("li");
      checkElement.slideDown(animationSpeed, function () {
        checkElement.addClass('open');
        parent.find('li.is-expanded').removeClass('is-expanded');
        parent_li.addClass('is-expanded');
      });
    }
    if (checkElement.is(slideMenuSelector)) {
      e.preventDefault();
    }
  });
  
  // Activate sidebar slide toggle
  $("[data-toggle='sub-slide-sub']").on('click', function (e) {
    var $this = $(this);
    var checkElement = $this.next();
    var animationSpeed = 300,
      slideMenuSelector = '.sub-slide-menu-sub';
    if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
      checkElement.slideUp(animationSpeed, function () {
        checkElement.removeClass('open');
      });
      checkElement.parent("li").removeClass("is-expanded");
    }
    else if ((checkElement.is(slideMenuSelector)) && (!checkElement.is(':visible'))) {
      var parent = $this.parents('ul').first();
      var ul = parent.find('ul:visible').slideUp(animationSpeed);
      ul.removeClass('open');
      var parent_li = $this.parent("li");
      checkElement.slideDown(animationSpeed, function () {
        checkElement.addClass('open');
        parent.find('li.is-expanded').removeClass('is-expanded');
        parent_li.addClass('is-expanded');
      });
    }
    if (checkElement.is(slideMenuSelector)) {
      e.preventDefault();
    }
  });
  
  //Activate bootstrip tooltips
  $(function() {
    $("[data-toggle='tooltip']").tooltip();
  })
  
  
  // ______________Active Class
  $(".app-sidebar li a").each(function () {
    var pageUrl = window.location.href.split(/[?#]/)[0];
    if (this.href.split(/[?#]/)[0] == pageUrl) {
      $(this).addClass("active");
      $(this).parent().addClass("is-expanded").css('display', '');
      $(this).parent().parent().prev().addClass("active");
      $(this).parent().parent().addClass("open").css('display', '');;
      $(this).parent().parent().prev().addClass("is-expanded").css('display', '');;
      $(this).parent().parent().parent().addClass("is-expanded").css('display', '');;
      $(this).parent().parent().parent().parent().addClass("open").css('display', '');;
      $(this).parent().parent().parent().parent().prev().addClass("active");
      $(this).parent().parent().parent().parent().parent().addClass("is-expanded").css('display', '');;
    }
  });
  
  var toggleSidebar = function () {
    var w = $(window);
    if (w.outerWidth() <= 767) {
      $("body").addClass("sidebar-gone");
      $(document).off("click", "body").on("click", "body", function (e) {
        if ($(e.target).hasClass('sidebar-show') || $(e.target).hasClass('search-show')) {
          $("body").removeClass("sidebar-show");
          $("body").addClass("sidebar-gone");
          $("body").removeClass("search-show");
        }
      });
    } else {
      $("body").removeClass("sidebar-gone");
    }
  }
  toggleSidebar();
  $(window).resize(toggleSidebar);
}