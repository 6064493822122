import { Route, Routes } from "react-router-dom";
import { Metrology } from "../metrology";
import { Mode } from "../common";
import { ComfortDashboard } from "../dashboards/comfort";
import { Auth } from '@src/auth';
import SwitchSite from '@src/sites';
import CosticForm from "@src/costic";

export default function Router() {
  return (
    <Routes>
      <Route
        path="/account/*"
        element={<Auth/>}
      />
      <Route path='/home/sites/redirect' element={<SwitchSite/>} />
      <Route
        path="/comfort/metrology"
        element={<Metrology mode={Mode.COMFORT} />}
      />
      <Route path="/comfort/dashboard" element={<ComfortDashboard />}>
        <Route path="list" element={null} />
        <Route path="table" element={null} />
        <Route path="analysis" element={null} />
      </Route>
      <Route
        path="/energy/metrology"
        element={<Metrology mode={Mode.ENERGY} />}
      />
      <Route
        path="/energy/costic/upload"
        element={<CosticForm />}
      />
    </Routes>
  );
}
