import { useEffect, useState } from 'react';
import './index.scss';
import axios from 'axios';
import { Modal } from '@mui/base';
import { Box, Typography } from '@mui/material';

const url = process.env.EFFI_URL;
const urlSession = url + "/api/method/session";
const production = !url.includes("staging");

export default function SwitchSite() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const askAccess = () => {
    setLoading(true);
    axios.put('/account/api/request_access/')
      .then(() => {
        setSuccess(true);
      })
      .catch(() => setSuccess(false))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    axios.get('https://' + urlSession)
      .then(res => setSession(res.data));
  }, []);
  const [openModal, setOpenModal] = useState(false);
  const realEstates = Object.values(session?.tree?.realEstates || {})[0] as any;
  const finalUrl = production ? realEstates?.url + '.' + url : url;
  const apps = {
    effipilot: finalUrl + '/reporting',
    insight: finalUrl + '/insight',
  };
  const handleRedirect = async (app: string) => {
    if (production !== true) {
      await axios.get(`https://${urlSession}?filters={"realEstateName":"${realEstates?.url}"}`);
    }
    window.open('https://' + apps[app as keyof typeof apps], "_blank");
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col col-6">
          <div onClick={() => handleRedirect("insight")} className="card hoverable" css="height: 175px">
            <p className="card-header">{gettext("Accéder à")}</p>
            <div className="card-body">
              <img src="/static/img/brand/performance.svg"
                alt="Logo Effipilot"
                width="650"
                height="auto" />
            </div>
          </div>
        </div>
        <div className="col col-6">
          <div onClick={() => handleRedirect("effipilot")} className="card hoverable" css="height: 175px">
            <p className="card-header">{gettext("Accéder à")}</p>
            <div className="card-body">
              <img src="/static/img/brand/effipilot.svg"
                alt="Logo Effipilot"
                width="350"
                height="auto" />
            </div>
          </div>
        </div>
        <div className="col col-6 mt-5 text-center mb-3 h1">
          {gettext("Ou")}
        </div>
        <div className="col col-6">
          <div onClick={() => setOpenModal(!openModal)} className="card hoverable" css="height: auto">
            <div className='col col-8'>
              <p className="card-header">{gettext("Demander l'accès à")}</p>
              <div css="line-height: 95px" className="card-body">
                <img src="/static/img/brand/Accenta_Insight_confort_Logo_2021_noir_svg_optim.svg"
                  alt="Logo Insight Energie confort"
                  width="550"
                  height="auto" />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component={'div'} className='card card-primary' sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {gettext("Demander l'accès a Insight Energie - Confort")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {gettext("Vous êtes sur le point de demander l'accès à la plateforme Energie - Confort")}
            </Typography>
            <button onClick={askAccess} className='my-3 btn btn-primary'>
              {loading ?
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                :
                success ?
                  <i className="text-light fa-solid fa-check"></i>
                  :
                  gettext("Envoyer la demande")
              }
            </button>
            {
              success === false
                ?

                <Typography className='text-danger' id="modal-modal-description" sx={{ mt: 2 }}>
                  {gettext("Une erreur est survenue merci de réessayer")}
                </Typography>
                :
                ""
            }
            <Typography fontSize={12}>
              {gettext("Vous recevrez une confirmation par mail lorsque vôtre accès sera créé")}
            </Typography>
          </Box>
        </Modal>
        <div className='col col-12 text-center'>
          <hr />
          <p>
            {gettext("Vous voyez cette page car vous n'avez pas accès aux produits Insight Energie - Confort")}
          </p>
          <p>
            {gettext("Si vous pensez devoir accéder à cette page, sélectionnez la troisième carte")}
          </p>
        </div>
      </div>
    </div>
  );
}