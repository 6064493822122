export function getFloorNumber(element: string) {
    if (!(!!element)) return -99;
    let floor = element.trim().toUpperCase();

    const charToReplace = {"RDC": "R+0", "W0": "R+"};
    let newFloor = floor;

    Object.keys(charToReplace).forEach((key) => {
        if (newFloor.includes(key)) {
            newFloor = newFloor.replace(
                key, charToReplace[key as keyof typeof charToReplace]
            );
        }
    });

    const pattern = /[Rr][\+-]\d{1,2}/;
    const patternFound = newFloor.match(pattern);
    if (patternFound) {
        const res = patternFound[0];
        const splitFloor = res.split("R");
        const signNumber = splitFloor[1].startsWith('+') ? splitFloor[1] : splitFloor[1].replace('-', '-');
        return parseInt(signNumber, 10);
    }

    if (!isNaN(parseInt(newFloor, 10))) {
        return parseInt(newFloor, 10);
    }

    return newFloor.charCodeAt(0);
}
