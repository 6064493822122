import * as htmlToImage from 'html-to-image';
import * as ExcelJS from 'exceljs';
import { toJpeg, toPng, toSvg } from 'html-to-image';
import Papa from 'papaparse';
import pdfMake from "pdfmake/build/pdfmake";
import { useSelector } from 'react-redux';
import { RootState } from '../../../context/store';
import { Mode } from "@src/common";


interface Props {
    HTMLElementId: string;
    chartType: string;
}

interface Label {
    label: string;
    unit: string;
}

interface LineLabel {
    temp: Label;
    hum: Label;
    batterie: Label;
    motion: Label;
    co2: Label;
    lum: Label;

    [key: string]: Label;
}

interface excelDataStruct {
    [key: string]: number | string
}
interface excelDataStructByTS {
    [ts: number]: excelDataStruct 
}

type readableDataForPapaParse = [excelDataStruct[], string[]]


function convertSvgToPdf(image: string) {
    const docDefinition = {
        content: [
            {
                image,
                width: 500
            },
        ],
    };
    return pdfMake.createPdf(docDefinition);
}

export default function DropdownDownload({HTMLElementId, chartType}: Props) {
    const mode = useSelector((state: RootState) => state.metrology.mode);
    let data: any;
    if (mode == Mode.COMFORT) {
        const comfortDataBySensor = useSelector((state: RootState) => state.metrology.comfortDataBySensor);
        data = Object.values(comfortDataBySensor).filter(sensor => sensor.display).map(sensorToDisplay => sensorToDisplay.sensor) || [];
    }
    else {
        const energyDataBySensor = useSelector((state: RootState) => state.metrology.energyDataBySensor);
        data = {}
        Object.entries(energyDataBySensor).map(([eng, engData]) => {
            data[eng] = Object.values(engData).filter((sensor: any) => {return sensor.display}).map((sensorToDisplay: any) => {return sensorToDisplay.sensor})
        })
        
    }
    const sensors = useSelector(
        (state: RootState) => state.metrology.selectedSensors
    );
    
    const download = (url: string, fileName: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
    }

    const transformFloatToString = (value: string | number, lang: string) => {
        if (typeof value === 'number' && lang === 'fr') {
            const stringValue = value.toString();
            return stringValue.replace('.', ',');
        }
        return value;
    };

    const MS_IN_SECOND = 1_000;
    const getLineLabel = (): LineLabel => {
        return {
            temp: { label: gettext("Température"), unit: "°C" },
            hum: { label: gettext("Humidité"), unit: "%" },
            batterie: { label: gettext("Batterie"), unit: "V" },
            motion: { label: gettext("Mouvement"), unit: "" },
            co2: { label: gettext("Co2"), unit: "ppm" },
            lum: { label: gettext("Luminosité"), unit: "lux" }
        }
    }

    const fromIdToQuantities: any = {
        "comfort": ["temperature", "humidity"],
        "temp": ["temperature"],
        "hum": ["humidity"],
        "co2": ["co2"],
        "motion": ["motion"],
        "lum": ["brightness"],
        "batterie": ["batterie"],
    }

    const fromIdQuantitiesToLineLabel: any = {
        "temperature": "temp",
        "humidity": "hum",
        "co2": "co2",
        "motion": "motion",
        "brightness": "lum",
        "batterie": "batterie",
    }

    const datetimeLabel = "DateTime"
    
    const fromIdToEnergyInfos: any = {
        "elec": {"quantityName": "Puissance électrique", "quantityUnit": "kW", "quantity": "value"},
        "capt": {"quantityName": "Température", "quantityUnit": "°C", "quantity": "_value"},
        "hot": {"quantityName": "Réseau de chaud", "quantityUnit": "kW", "quantity": "value"},
        "cold": {"quantityName": "Réseau de froid", "quantityUnit": "kW", "quantity": "value"},
    }
        
    const parseEnergyData = (
        ids: string[],
        dateLabel: string,
        toBeXLS: boolean,
        lang?: string
        ): readableDataForPapaParse => {
            const columnsNames: string[] = ["DateTime"]
            const dataByTS: excelDataStructByTS = {}
            for (const raw_id of ids){
                const id = raw_id.replace("line-", "")
                for (const sensor of (data as any)[id]) {
                    for (const dataPoint of sensor.data) {
                        let row: excelDataStruct = {}
                        if (dataPoint[dateLabel] in dataByTS) {
                            row = dataByTS[dataPoint[dateLabel]]
                        }
                        else {
                            row["DateTime"] = (new Date(dataPoint[dateLabel] * MS_IN_SECOND)).toLocaleString()
                        }
                        
                        const quantityName = fromIdToEnergyInfos[id]["quantityName"]
                        const quantityUnit = fromIdToEnergyInfos[id]["quantityUnit"]
                        const quantity = fromIdToEnergyInfos[id]["quantity"]
                        const sensorLabel = sensor["label"]
                        const columnName = `${quantityName} (${sensorLabel}) [${quantityUnit}]`
                        if (!(columnsNames).includes(columnName)) {
                            columnsNames.push(columnName);
                        }
                        row[columnName] = toBeXLS? dataPoint[quantity] : transformFloatToString(dataPoint[quantity], lang)

                        dataByTS[dataPoint[dateLabel]] = row
                    }
                }
            }
            return [Object.values(dataByTS), columnsNames]
    }

    const parseComfortData = (
        ids: string[],
        lineLabel: any,
        dateLabel: string,
        toBeXLS: boolean,
        lang: string
        ): readableDataForPapaParse => {
            const columnsNames: string[] = ["DateTime"]
            const dataByTS: excelDataStructByTS = {}
            
            for (const sensor of (data as Array<any>)) {
                for (const dataPoint of sensor.data) {
                    let row: excelDataStruct
                    if (dataPoint[dateLabel] in dataByTS) {
                        row = dataByTS[dataPoint[dateLabel]]
                    }
                    else {
                        row = {}
                        row["DateTime"] = (new Date(dataPoint[dateLabel] * MS_IN_SECOND)).toLocaleString()
                    }
                    
                    for (const id of ids){
                        for (const quantity of fromIdToQuantities[id]) {
                            const quantityName = lineLabel[fromIdQuantitiesToLineLabel[quantity]].label
                            const quantityUnit = lineLabel[fromIdQuantitiesToLineLabel[quantity]].unit as any
                            const sensorLabel = sensor["label"] 
                            const columnName = `${quantityName} (${sensorLabel}) [${quantityUnit}]`
                            if (!(columnsNames).includes(columnName)) {
                                columnsNames.push(columnName);
                            }
                            row[columnName] = toBeXLS? dataPoint[quantity] : transformFloatToString(dataPoint[quantity], lang)
                        }
                    }
                    dataByTS[dataPoint[dateLabel]] = row
                }
            }
            return [Object.values(dataByTS), columnsNames]
        }
    const dispatchParsingMode = (ids: string[], toBeXLS: boolean): readableDataForPapaParse => {
        const lang = document.documentElement.lang
        const lineLabel: LineLabel = getLineLabel();

        if (mode == "comfort") {
            const dateLabel = "ts"
            const sensorsNames = (sensors.default as any).reduce(
                (accumulator: any, current: any) => {
                    accumulator[current.name] = current.gui_informations.label; return accumulator
                }, Object.create(null));
            return parseComfortData(ids, lineLabel, dateLabel, toBeXLS, lang)
        }
        else {
            const dateLabel = "date"
            const sensorsNames = Object.values(sensors as any).flat(1).reduce(
                (accumulator: any, current: any) => {
                    accumulator[current.name] = current.gui_informations.label; return accumulator
                }, Object.create(null));
            return parseEnergyData(ids, dateLabel, toBeXLS, lang)
        }
    };

    const createCsvFile = (ids: string[]) => {
        const [sensorsData, columnsNames]: readableDataForPapaParse = dispatchParsingMode(ids, false)
        const csvData = Papa.unparse(sensorsData, { delimiter: ';', columns: columnsNames })
        const csvFile = new Blob(["\uFEFF" + csvData], { type: 'text/csv;charset=utf-8;' });
        return URL.createObjectURL(csvFile);
    }

    const getColWidth = (data: excelDataStruct[]) => {
        const res: {[key: string]: number} = {}
        data.map((row: excelDataStruct) => {
            for (const [key, value] of Object.entries(row)) {
                if (res[key]) {
                res[key] = Math.max(res[key], key.length+1, value?.toString().length+1)
                }
                else {
                res[key] = Math.max(key.length+1, value?.toString().length+1)
                }
            }
        })
        return res
    }
    
    const toXLS = async (ids: string[]) => {
        const [sensorsData, columnsNames]: readableDataForPapaParse = dispatchParsingMode(ids, true) 
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        const colWidth = getColWidth(sensorsData)
        worksheet.columns = columnsNames.map((colName: string) => { return {
            header: colName,
            key: colName,
            width: colWidth[colName]
        }})

        for (const [indexRow, row] of sensorsData.entries()) {
            for (const [colName, cellValue] of Object.entries(row)) {
                (worksheet.getCell(indexRow+2, columnsNames.indexOf(colName)+1).value as any) = cellValue
            }
        }
        
        const buffer = await workbook.xlsx.writeBuffer();
        const dataBlob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        return URL.createObjectURL(dataBlob);
    }
    
    const handleAreaDownload = async (id: string, type: string, all?: boolean) => {
        const allowedExtensions = ["svg", "png", "jpeg", "pdf", "csv", "xlsx"];
        const element = document.getElementById(id);
        if (element === null) return;

        const svgURL = new XMLSerializer().serializeToString(element);
        const svgBlob = new Blob([svgURL], { type: `image/svg+xml;charset=utf-8` });
      
        const url = URL.createObjectURL(svgBlob);
        let fileExtension = allowedExtensions.find(e => type.includes(e));
        let dataURL = "";
        switch (type) {
            case "png":
                dataURL = await toPng(element);
                break;
            case "jpeg":
                dataURL = await toJpeg(element, { backgroundColor: "white" });
                break;
            case "svg":
                dataURL = await toSvg(element);
                break;
            case "csv":
                dataURL = createCsvFile([id]);
                break;
            case "xlsx":
                const ids: string[] = all? ((mode == "comfort")? Object.keys(fromIdToQuantities) : Object.keys(data)) : [id]
                id = all? "all_graphs" : id
                dataURL = await toXLS(ids);
                break
            case "pdf":
                const imageB64 = await htmlToImage.toPng(element);
                const pdf = convertSvgToPdf(imageB64);
                pdf.download(`chart_${id}.${fileExtension}`);
                return;
        }
        download(dataURL, `chart_${id}.${fileExtension}`);
        URL.revokeObjectURL(url);
    }


    return (
        <div className="dropdown text-right dropleft">
            <button className="btn btn-outline-secondary"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false">
                <i className="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <div className="dropdown-menu">
                <a className="dropdown-item chart-export-item">
                    {gettext("See in full")} <span className="badge badge-success justify-content-end">{gettext("Soon")}</span>
                </a>
                <a className="dropdown-item chart-export-item">
                    {gettext("Print")} <span className="badge badge-success justify-content-end">{gettext("Soon")}</span>
                </a>
                <div className="dropdown-divider"></div>
                <a
                    className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "svg")}>
                    <span>{gettext("Download as SVG")}</span>
                </a>
                <a className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "png")}>
                    <span>{gettext("Download as PNG")}</span>
                </a>
                <a className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "jpeg")}>
                    {gettext("Download as JPEG")}
                </a>
                <a className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "pdf")}>
                    {gettext("Download as PDF")}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "csv")}>
                    {gettext("Download as CSV")}
                </a>
                <a className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "xlsx")}>
                    {gettext("Download as XLS")}
                </a>
                <a className="dropdown-item chart-export-item"
                    onClick={() => handleAreaDownload(HTMLElementId, "xlsx", true)}>
                    {gettext("Download all graph as XLS")}
                </a>
                <a className="dropdown-item chart-export-item">
                    {gettext("View data table")} <span
                        className="badge badge-success justify-content-end">{gettext("Soon")}</span>
                </a>
            </div>
        </div>
    )
}
