import { Equipment } from "@src/common/api";
import { SelectOption } from "@src/common/select";
import { removeDuplication } from "@src/common/utils";
import { useEffect, useState } from "react";
import { MultiValue } from "react-select";
import SensorSelect from "./sensor-select";


export default function SensorsFilters({allEquipments, onSelectFilter}: Props) {
    const allOptions = generateGroupedOption(allEquipments);

    const [buildingSelection, setBuildingSelection] = useState<MultiValue<SelectOption>>();
    const [floorSelection, setFloorSelection] = useState<MultiValue<SelectOption>>();
    const [roomTypeSelection, setRoomTypeSelection] = useState<MultiValue<SelectOption>>();
    const [devEUISelection, setDevEUISelection] = useState<MultiValue<SelectOption>>();
    const [areaSelection, setAreaSelection] = useState<MultiValue<SelectOption>>();
    const [tenantSelection, setTenantSelection] = useState<MultiValue<SelectOption>>();

    const filterOptionByEquipment = (option: SelectOption) => {
        return allEquipments.find(equipment => {
            const values = Object.values(equipment.gui_informations);
            return values.includes(option.value) || equipment.name === option.value;
        })
    }

    const setSelectionByEquipment = (selection: MultiValue<SelectOption>, setSelection: (values: MultiValue<SelectOption>) => void) => {
        if (selection) {
            const newSelection = selection.filter(filterOptionByEquipment);
            setSelection(newSelection);
        }
    }

    useEffect(() => {
        setSelectionByEquipment(buildingSelection, setBuildingSelection);
        setSelectionByEquipment(floorSelection, setFloorSelection);
        setSelectionByEquipment(roomTypeSelection, setRoomTypeSelection);
        setSelectionByEquipment(tenantSelection, setTenantSelection);
        setSelectionByEquipment(areaSelection, setAreaSelection);
        setSelectionByEquipment(devEUISelection, setDevEUISelection);
    }, [allEquipments]);

    const filterEquipmentsByOptions = (type: string, options: MultiValue<SelectOption>) => {
        const selection: Options = {
            "building": buildingSelection,
            "etage": floorSelection,
            "type": roomTypeSelection,
            "tenant": tenantSelection,
            "name": devEUISelection,
            "zone": areaSelection,
        }
        // Override current Selection.
        selection[type] = options;
        let newEquipments: Equipment[] = allEquipments;
        for (const [type, selectedOptions] of Object.entries(selection)) {
            if (selectedOptions?.length) {
                newEquipments = newEquipments.filter((equipment) => {
                    const value = type == "name" ? equipment.name : equipment.gui_informations[type]
                    return (!!(selectedOptions.find(option => option.value === value)));
                });
            }
        }
        return newEquipments;
    };

    const setStateByType = (type: string, options: MultiValue<SelectOption>) => {
        switch (type) {
            case "building":
                setBuildingSelection(options);
                break
            case "etage":
                setFloorSelection(options);
                break
            case "name":
                setDevEUISelection(options);
                break
            case "zone":
                setAreaSelection(options);
                break
            case "type":
                setRoomTypeSelection(options);
                break
            case "tenant":
                setTenantSelection(options);
                break
        }

    }
    const onSelectHandler = (type: string, options: MultiValue<SelectOption>) => {
        setStateByType(type, options);
        const filteredEquipments = filterEquipmentsByOptions(type, options);
        onSelectFilter(filteredEquipments);
    }

    return (
        <>
            <div className="panel-filters-sensors" id="filterSensors">
                <div className="panel panel-default mb-2">
                    <div className="panel-heading1">
                        <h4 className="panel-title1">
                            <a className="accordion-toggle collapsed"
                               data-toggle="collapse"
                               data-parent="#filterSensors"
                               href="#collapsePeriod"
                               aria-expanded="true">{gettext("Filtres des capteurs")}</a>
                        </h4>
                    </div>
                </div>
                <div id="collapsePeriod"
                     className="panel-collapse collapse"
                     role="tabpanel"
                     aria-expanded="false">
                    <div className="panel-body" css="margin-bottom: .5rem">
                        <div className={"col"}>
                            <div className="row">
                                <SensorSelect
                                    type="building"
                                    placeHolder="Bâtiments / Lot"
                                    bootstrapCol="4"
                                    options={allOptions.building}
                                    selectedOptions={buildingSelection}
                                    onSelect={onSelectHandler}/>

                                <SensorSelect
                                    type="etage"
                                    placeHolder="Etage"
                                    bootstrapCol="4"
                                    options={allOptions.etage}
                                    selectedOptions={floorSelection}
                                    onSelect={onSelectHandler}/>

                                <SensorSelect
                                    type="type"
                                    placeHolder="Type de salle"
                                    bootstrapCol="4"
                                    options={allOptions.type}
                                    selectedOptions={roomTypeSelection}
                                    onSelect={onSelectHandler}/>
                            </div>

                            <div className="row mt-3">
                                <SensorSelect
                                    type="name"
                                    placeHolder="DevEUI"
                                    bootstrapCol="4"
                                    options={allOptions.name}
                                    selectedOptions={devEUISelection}
                                    onSelect={onSelectHandler}/>

                                <SensorSelect
                                    type="zone"
                                    placeHolder="Zone"
                                    bootstrapCol="4"
                                    options={allOptions.zone}
                                    selectedOptions={areaSelection}
                                    onSelect={onSelectHandler}/>

                                <SensorSelect
                                    type="tenant"
                                    placeHolder="Locataire"
                                    bootstrapCol="4"
                                    options={allOptions.tenant}
                                    selectedOptions={tenantSelection}
                                    onSelect={onSelectHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

interface Options {
    [key: string]: MultiValue<SelectOption>;
}

interface Props {
    allEquipments: Equipment[],
    onSelectFilter: (equipments: Equipment[]) => void;
}



function generateGroupedOption(equipments: Equipment[]) {
    const labels = [
        {value: gettext('Bâtiments / Lot'), field: "building"},
        {value: gettext("Etage"), field: "etage"},
        {value: gettext("DevEUI"), field: "name"},
        {value: gettext("Zone"), field: "zone"},
        {value: gettext("Type de Salle"), field: "type"},
        {value: gettext("Locataire"), field: "tenant"}
    ];

    const groupedOptions: Options = {};

    labels.forEach(label => {
        const options = equipments?.map((equipment: Equipment) => {
            const data = label.field == "name" ? equipment.name : equipment.gui_informations[label.field]
            if (!data) return;
            return {
                value: data,
                label: data,
                color: "",
                type: label.field,
            }
        }).filter(o => !!o?.label)

        groupedOptions[label.field] = removeDuplication(options || [])
    });
    return groupedOptions;
}

