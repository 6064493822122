import {
    PeriodAverage
} from "@src/dashboards/comfort/components/data/sensor-analysis";

interface Props {
    data: PeriodAverage
}
export default function TemperaturePeriodAverage({data}: Props) {
    return <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <p className="analysis-slider-text">
            <span className="bold">{gettext("Occupation")}</span>
            : {data?.occupied ?? "N/A"}°C
        </p>
        <p className="analysis-slider-text">
            <span className="bold">{gettext("Inoccupation")}</span>
            : {data?.unoccupied ?? "N/A"}°C
        </p>
        <p className="analysis-slider-text">
            <span className="bold">{gettext("Weekend")}</span>
            : {data?.weekend ?? "N/A"}°C
        </p>
    </div>
}