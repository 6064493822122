import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  data: any
}

const initialState: InitialState = { data: null }

const costicSlice = createSlice({
  initialState,
  name: "costic",
  reducers: { storeData(state, action) { state.data = action.payload } }
})

export default costicSlice.reducer
export const {storeData} = costicSlice.actions