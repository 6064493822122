import { Spinner } from "@src/common/components/is-loading";
import { RootState } from "@src/context/store";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PlainButton } from "../buttons/button-export";

interface Data {
  ts: number;
  temperature: number;
  co2: number;
  [key: string]: any;
}

interface TableBodyDataProps {
  current: Data;
  previous: Data;
  label: string;
  deveui: string;
  zone: string;
  etage: string;
}

export default function SensorTableData({current, previous, label, deveui, zone, etage}: TableBodyDataProps) {
  const lang = document.documentElement.lang;
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [field, setField] = useState({value: "temperature", unit: "°C"});
  const [isLoading, setIsLoading] = useState(false);
  const [lastMessageDate, setLastMessageDate] = useState(null);
  const hoverCard = useRef(null);
  const {displaying} = useSelector((state: RootState) => state.dashboard.table);

  useEffect(() => {
    const unit = displaying === "temperature" ? "°C" : "ppm";
    setField({value: displaying, unit});  
  }, [displaying]);
  


  const setHoverCardPosition = (event: any) => {
    const  { top, left } = document.querySelector(".card-dashboard-comfort").getBoundingClientRect();
    const {clientX, clientY, pageY} = event;
    const heightEventText = event.currentTarget.getBoundingClientRect().height;
    
    const table = document.getElementById("sensor-table-data").getBoundingClientRect();

    const {width, height} = hoverCard.current.getBoundingClientRect();
    
    let leftPositionValue = clientX - left;
    let topPositionValue = clientY - top + (heightEventText / 2);
    

    if (width + (clientX - left) > table.width) {
      const overflowValue = (width + (clientX - left)) - table.width;
      leftPositionValue -= overflowValue;
    }
    if (height + pageY > document.body.offsetHeight) {
      topPositionValue -= height + heightEventText;
    }

    hoverCard.current.style.left = `${leftPositionValue}px`;
    hoverCard.current.style.top = `${topPositionValue}px`;
  }
  
  const handleMouseEnter = (event: any) => {
    hoverCard.current.style.display = "block";
    setHoverCardPosition(event);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverCard.current.style.display = isClicked ? "block" : "none";
    setIsHovered(false);
  };

  const handleClick = (event: any) => {
    setIsClicked(true);
    if (isHovered) return;
    hoverCard.current.style.display = "block";
    setHoverCardPosition(event);
  }

  const handleClose = () => {
    hoverCard.current.style.display = "none";
    setIsClicked(false);
  }

  const getTemperaturePastille = (temperature: number, lastMessageDate: moment.Moment) => {
      const today = moment();
      const hourDelta = Math.abs(lastMessageDate.diff(today, 'hours'));
      
      const SUMMER_MIN = 20;
      const SUMMER_MAX = 26;
      const WINTER_MIN = 19;
      const WINTER_MAX = 21;

      const currentYear = today.year()
      const isSummer = today.isBetween(`${currentYear}-04-15`, `${currentYear}-10-14`);

      const minTemp = isSummer ? SUMMER_MIN : WINTER_MIN;
      const maxTemp = isSummer ? SUMMER_MAX : WINTER_MAX;

      let className = "";
  
      if (!temperature || hourDelta >= 72) {
        return "grey";
      }
      
      if (temperature >= minTemp && temperature <= maxTemp) {
        className = "green";
      } else if (
        (temperature >= minTemp - 1 && temperature < minTemp) ||
        (temperature > maxTemp && temperature <= maxTemp + 1)
      ) {
        className = "yellow";
      } else {
        className = "red";
      }
      return className
  }

  const getCo2Pastille = (co2: number, lastMessageDate: moment.Moment) => {
      const today = moment();
      const hourDelta = Math.abs(lastMessageDate.diff(today, 'hours'));
      let classeName = "";
      
      if (!co2 || hourDelta >= 72) {
        return "grey";
      }
      
      if (co2 < 1100) {
        classeName = 'green';
      } else if (co2 >= 1100 && co2 < 1500) {
        classeName = 'yellow';
      } else if (co2 > 1500) {
        classeName = 'red';
      }

      return classeName;
  }
  
  const getPastilleClass = (ts: number, data: number) => {
    if (!data) return "grey";

    const lastMessageDate = moment.unix(ts);
    let className = "";
    switch(field.value){
      case "temperature":        
        className = getTemperaturePastille(data, lastMessageDate);
        break;
      case "co2":
        className = getCo2Pastille(data, lastMessageDate);
    }
    return className;
  }

  const isTimestampOlderThan72Hours = (timestamp: number) => {
      const today = moment();
      const sensorData = moment.unix(timestamp);
      const hourDelta = Math.abs(today.diff(sensorData, 'hours'));
      return hourDelta >= 72;
  }

  const fetchLastMessage = (deveui: string) => {
    setIsLoading(true);
    axios
    .get(
      `/api/v1/comfort/sensor/lastmessage?deveui="${[deveui]}"`
    )
    .then((res) => {
      if (!res.data?.ts) {
        setLastMessageDate("N/A");
        return;
      }
      const date_= moment(res.data.ts);
      const formattedDate = date_.locale(lang).format('LLL');
      setLastMessageDate(formattedDate);
    })
    .finally(() => setIsLoading(false));
  }

  const isOlderThan72Hours = isTimestampOlderThan72Hours(current.ts);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{width: "max-content"}}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}>
          <span className={"pastille " + getPastilleClass(current.ts, current[field.value])}></span>
          <span 
            className="mx-1"
          >
            {(field.value === "temperature") ? 
              current[field.value]?.toFixed(1) || "--.--" 
              : 
              current[field.value] || '--.--'}
            {field.unit}
          </span>
        </div>
      </div>
      <div className="table-card-hover" ref={hoverCard}>
        <div className="close-icon-container">
          <p>Label&nbsp;:&nbsp;<span><b>{label}</b></span></p>
          { isClicked && (
            <button onClick={handleClose}>
                  <i className="fa-regular fa-circle-xmark close-icon"></i>
              </button>
            )
          }
        </div>
        <p>DevEUI&nbsp;:&nbsp;<span>{deveui}</span></p>
        <p>
          {gettext(displaying === "temperature" ? "Température" : "Co2")}&nbsp;:&nbsp;
          <span>{current[field.value] || '-'}{field.unit}</span>
        </p>
        <p>
          {gettext(displaying === "temperature" ? "Température" : "Co2")} précédente&nbsp;:&nbsp;
          <span>{previous[field.value] || '-'}{field.unit}</span>
        </p>
        <p>Date&nbsp;:&nbsp;
            <span>
              {
                isOlderThan72Hours ? 
                  <>
                  <span style={{color: "red", fontWeight: "bold"}}>{lastMessageDate ?? gettext("supérieur à 72h")}&nbsp;</span>
                  { isLoading && <Spinner width="16px" height="16px" />}
                  </>
                : 
                  <span>{moment.unix(current.ts).locale(lang).format('LLL')}</span>
              }
            </span>
        </p>
        <p>Emplacement&nbsp;:&nbsp; {zone} - {etage}</p>
        {
          !(!!lastMessageDate) && isOlderThan72Hours && (
            <PlainButton
              onClickHandler={() => fetchLastMessage(deveui)} 
              text={gettext("Afficher la date du dernier message")} />
          )
        }
      </div>
    </div>
  );
}
