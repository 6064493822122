import { Route, Routes } from 'react-router-dom';
import { Signin } from './components/signin';
import Layout from './components/layout';
import Forgot from './components/forgot';
import Reset from './components/reset';

export function Auth() {
  return (
    <Layout>
      <Routes>
        <Route path='signin' element={<Signin />} />
        <Route path='password/forgot' element={<Forgot />} />
        <Route path='password/reset' element={<Reset />} />
      </Routes>
    </Layout>
  );
}