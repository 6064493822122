import SensorSelect, { SelectOption } from "@src/common/select";
import { tableFilter as storeFilter } from "@src/context/reducers/dashboard";
import { RootState } from "@src/context/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiValue } from "react-select";


function getOptionsFromStringArray(array: string[]) {
  return array.map((value) => {
    return  {
      label: value,
      value,
      color: "#000000",
      type: ""
    }
  });
}

export default function TableFilter() {
  const {types, equipments, filter} = useSelector((state: RootState) => state.dashboard.table);

  const [typeOptions, setTypeOption] = useState<MultiValue<SelectOption>>([]);
  const [typeOptionsSelected, setTypeOptionsSelected] = useState<MultiValue<SelectOption>>([]);

  const dispatch = useDispatch();
  const dispatchFilter = (data: any) => dispatch(storeFilter(
    Object.assign({}, filter, data))
  );

  useEffect(() => {
    if (!filter.types.length) {
      dispatchFilter({equipments})
      return;
    }
    const oldEquipments = filter.equipments.map(e => e.gui_informations.type);
    const filteredEquipments = equipments.filter(
        e => oldEquipments.includes(e.gui_informations.type)
    );
    dispatchFilter({equipments: filteredEquipments});
  }, [equipments]);
  
  useEffect(() => {
    const options = getOptionsFromStringArray(types);
    setTypeOption(options);
  }, [types]);
  
  return (
    <div className="mb-5">
      <label htmlFor="data-type">{gettext("Types de salle")}</label>
      <SensorSelect
          placeholder={gettext('Toutes les types')}
          isMulti={true}
          options={typeOptions}
          value={typeOptionsSelected}
          onChange={(options) => {
            setTypeOptionsSelected(options);
            const equipmentByTypes = !(options.length) ? equipments : equipments.filter(
                e => options.map(o => o.value).includes(e.gui_informations.type)
            );
            dispatchFilter({types: options, equipments: equipmentByTypes});
          }}
          className=""
      />
    </div>
  );
}