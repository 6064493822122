import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


interface Props {
    text?: string;
    awesomeClassIcon?: string; 
    onExportHandler?: () => void;
    onClickHandler?: () => void;
}

const StyledButton = styled(Button)({
  backgroundColor: 'rgb(4, 31, 104)',
});

export default function ButtonExport({onExportHandler, text="Exporter", awesomeClassIcon="fa-solid fa-file-arrow-down"}: Props) {
    return (
        <StyledButton 
            variant="contained"
            className="d-flex align-items-center"
            onClick={onExportHandler}>
                <i className={`${awesomeClassIcon} mr-2`}></i>
                <span style={{top: "2px", position: "relative"}}>
                    {text}
                </span> 
        </StyledButton>
    )
}

export function PlainButton({onClickHandler, text=""}: Props) {
    return (
        <StyledButton 
            variant="contained"
            className="d-flex align-items-center"
            onClick={onClickHandler}>
                {text}
        </StyledButton>
    )
}