import { useState } from "react";
import { Legend, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";


const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
  const sin = Math.sin(-RADIAN * midAngle * 1.1);
  const cos = Math.cos(-RADIAN * midAngle * 1.1);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 18;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={payload.fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={payload.fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${(value).toFixed(2)} ${payload.unit ?? "kWh"}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

interface Props {
    data: any;
    setShowText?: (value: boolean) => void;
    // containerHeight?: string | number;
    // containerWidth?: string | number;
    // chartHeight?: number;
    // chartWidth?: number;
}

export default function StyledChart({data, setShowText}: Props) {
  const [activeIndex, setActivateIndex] = useState(-1);

  const onPieEnter = (_: any, index: number) => {
    setActivateIndex(index);
  };

  const onPieLeave = () => {
    setActivateIndex(-1);
  };

  return (
    <ResponsiveContainer width="100%" height={275}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
          onAnimationEnd={() => setShowText && setShowText(true)}
        />
        <Legend/>
      </PieChart>
    </ResponsiveContainer>
  );
}