import { InputHTMLAttributes, useEffect } from "react";

import daterangepicker from "daterangepicker";

import "daterangepicker/daterangepicker.css";
import moment, { Moment } from "moment";

export default function DateRangePicker({onChange, start, end, ...props}: Props & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const selector = `input[name=dates]#${props.id}`
  useEffect(() => {
    const dt = new daterangepicker(
      document.querySelector(selector),
      {
        showWeekNumbers: true,
        showISOWeekNumbers: true,
        timePicker: true,
        timePicker24Hour: true,
        showCustomRangeLabel: true,
        showDropdowns: true,
        timePickerIncrement: 15,
        alwaysShowCalendars: true,
        opens: "center",
        ranges: {
          [gettext("Today")]: [moment(), moment()],
          [gettext("Yesterday")]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          [gettext("Last 7 days")]: [moment().subtract(6, "days"), moment()],
          [gettext("Last 30 days")]: [moment().subtract(29, "days"), moment()],
          [gettext("This month")]: [moment().startOf("month"), moment().endOf("month")],
          [gettext("Last month")]: [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
          [gettext("12 rolling months")]: [
              moment().subtract(12, "months").endOf("day"),
              moment(),
          ],
          [gettext("Year N-1")]: [
            moment().subtract(1, "year").startOf("year"),
            moment().subtract(1, "year").endOf("year"),
          ],
          [gettext("Year N-2")]: [
            moment().subtract(2, "year").startOf("year"),
            moment().subtract(2, "year").endOf("year"),
          ],
        },
        startDate: start,
        endDate: end,
        locale: {
          format: "DD/MM/YYYY",
          separator: " - ",
          cancelLabel: gettext("Cancel"),
          applyLabel: gettext("Apply"),
          customRangeLabel: gettext("Custom"),
          weekLabel: "S",
          daysOfWeek: [
            gettext("Sun"),
            gettext("Mon"),
            gettext("Tue"),
            gettext("Wed"),
            gettext("Thu"),
            gettext("Fri"),
            gettext("Sat")
          ],
          monthNames: [
            gettext("January"),
            gettext("February"),
            gettext("March"),
            gettext("April"),
            gettext("May"),
            gettext("June"),
            gettext("July"),
            gettext("August"),
            gettext("September"),
            gettext("October"),
            gettext("November"),
            gettext("December")
          ],
        },
      }
    );

    $(selector).on("apply.daterangepicker", (e) => {
      onChange(dt.startDate.valueOf(), dt.endDate.valueOf());
    });
  });
  return (
    <input className="form-control form-control-sm text-center" {...props} name="dates" />
  );
}

interface Props {
  onChange(start: number, end: number): void|any,
  start: Moment,
  end: Moment
}
