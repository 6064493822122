import axios from "axios";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useSearchParams } from 'react-router-dom';

export default function Forgot() {
  const [params] = useSearchParams()
  const { register, handleSubmit, formState } = useForm<ForgotForm>({
    defaultValues: { email: "" },
  });
  const [csrf, setCsrf] = useState(null)
  useEffect(() =>{ axios.get('/csrf/').then(response => setCsrf(response.data))}, [])
  return (
    <div className="main-card-signin d-md-flex bg-white">
      <div className="wd-100p">
        <div className="main-signin-header">
          <h2>Forgot Password</h2>
          <h4>Please Enter Your Email</h4>
          {
            csrf
              ?
              <form method="POST" action='/account/password/forgot/?processed'>
                <div className="form-group">
                  <label>Email</label>
                  <input type="hidden" name="csrfmiddlewaretoken" value={csrf} />
                  <input
                    {...register("email")}
                    className="form-control"
                    placeholder="Enter your email"
                    name="email"
                    type="email"
                  />
                </div>
                <button className="btn btn-main-primary btn-block" type="submit">
                  Send
                </button>
              </form>
              :

              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
          }
          {
            params.has('processed')
            ?
            <div className='alert alert-info'>
              Si cet email existe, nous lui avons envoyé un mail à l'instant
            </div>
            :
            ""
          }
        </div>
      </div>
    </div>
  );
}

interface ForgotForm {
  email: string;
}
