import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "main";

import Router from "./routes";
import { store } from "./context/store";

import type {} from "styled-components/cssprop";
import axios, { AxiosHeaders } from 'axios';

declare global {
  function gettext(str: string): string;
}

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.interceptors.request.use(function (config) {
  if(config.url.includes('/csrf/') || !['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
    return config
  }
  return axios.get("/csrf/").then((response) =>
    Object.assign<typeof config, typeof config>(config, {
      headers: new AxiosHeaders({
        ...config.headers,
        "X-CSRFToken": response.data,
      }),
    })
  );
});

document.addEventListener("DOMContentLoaded", () => {
  const container = createRoot(document.querySelector("#root"));

  container.render(
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
});
