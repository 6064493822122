import { hashSync } from "bcryptjs";
import { useForm } from "react-hook-form";

import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useState } from "react";

export function Signin() {
  const { register, handleSubmit } = useForm<AuthForm>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ username, password }: AuthForm) => {
    setLoading(true);
    const hash = hashSync(
      password + username, "$2a$12$yK7nj5qtSMHjNW.92Y4Zt."
    );

    axios
      .post(
        "/account/api/signin/",
        { username, hash },
      )
      .then(() => {
        setSuccess(true);
      })
      .catch(err => {
        if (err.response?.status) {
          setError(err.response.status);
        }
        else {
          setError(true);
        }
      })
      .finally(() => setLoading(false));
  };

  if (success) {
    window.location.href = '/';
  }

  return (
    <div className="card-sigin">
      <div className="mb-5 d-flex" css="flex-direction: column;">
        <h1 className="main-logo1 ml-1 mr-0 my-auto tx-28">Insight</h1>
        <br />
        <h5 css="margin-left: 0.25rem">Energie - Confort</h5>
      </div>
      <div className="card-sigin">
        <div className="main-signup-header">
          {
            error === 404
              ?
              <div className='alert alert-danger'>
                {gettext('Ce couple utilisateur / mot de passe n\'existe pas')}
              </div>
              : [500, true].includes(error)
                ?
                <div className='alert alert-danger'>
                  {gettext('Une erreur inattendue est survenue')}
                </div>
                :
                ''
          }
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            action="{% url 'signin' %}?{{request.GET.urlencode}}"
          >
            <div className="form-group">
              <label>Email</label>
              <input
                {...register("username")}
                className="form-control"
                placeholder="Enter your email"
                name="username"
                id="username"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                {...register("password")}
                className="form-control"
                placeholder="Enter your password"
                name="password"
                id="password"
                type="password"
              />
            </div>

                <button className="btn btn-main-primary btn-block" type="submit">
            {
              loading
                ?

                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                :
                  gettext('Sign In')
                }
                </button>
          </form>
          <div className="main-signin-footer mt-5">
            <Link to={"/account/password/forgot"}>
              {gettext("Forgot your password ?")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

interface AuthForm {
  username: string;
  password: string;
}
