import { Data, MetrologyResponse } from "@src/common";
import { Equipment } from "@src/common/api";
import { useMemo } from "react";
import { useFetch } from "usehooks-ts";
import { LineChart } from "../../../../metrology/components/charts/line";
import Loading from "@src/common/components/is-loading";


interface Props {
    equipment: Equipment,
    startDate: string;
    endDate: string;
    buildingId: number;
}

export default function ModalCharts({equipment, startDate, endDate, buildingId}: Props) {
  const deveuiParam = new URLSearchParams("deveui=" + JSON.stringify({[equipment.name]: "#000000"}));
  let hasFetchData = false;
  let isLoading = true;
  const {
    data,
    error
  } = useFetch<MetrologyResponse>(`/api/v1/comfort/series?building=${buildingId}&${deveuiParam}&startDate="${startDate}"&endDate="${endDate}"&types="temp,co2"`);

  const energies = useMemo(() => {
    hasFetchData = true;
    isLoading = false;
    return (data || []) as Data[];
  }, [data]);
    
  if (error) {
    return <h1>Error ...</h1>;
  } else if (isLoading) {
    return <Loading height="auto" text={"chargement des donnée en cours"} />;
  }
  const labels = {[equipment.name]: equipment.gui_informations.label};

  let hasTemperature = false;
  let hasCo2 = false;
  if (energies.length > 0) {
    hasTemperature = energies[0].data.some((d: Data) => d.temperature !== null);
    hasCo2 = energies[0].data?.some((d: Data) => d.co2 !== null);
  }

  return (
    <>           
      {
        hasTemperature && (
          <div className="panel panel-default mb-4" id="accordion-temp">
            <div className="panel-heading1">
              <h4 className="panel-title1">
                <a className="accordion-toggle mb-0 collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion-temp"
                  href="#collapse-temp"
                  aria-expanded="false">
                  {equipment.gui_informations.label} - {gettext("Température ambiante")}
                </a>
              </h4>
            </div>

            <div id="collapse-temp"
              className="panel-collapse collapse show"
              role="tabpanel"
              aria-expanded="false">
              <div className="panel-body" style={{padding: 0}}>
                <div className="card mb-0">
                  <div className="card-body" style={{height: 425}}>
                    {
                      <LineChart
                        yAxisUnit='Température (°C)'
                        data={energies || []}
                        XKey="ts"
                        YKey="temperature"
                        startDate={startDate}
                        endDate={endDate}
                        lookupLabels={labels}>
                      </LineChart>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        hasCo2 && (
          <div className="panel panel-default mb-4" id="accordion-co2">
            <div className="panel-heading1">
              <h4 className="panel-title1">
                <a className="accordion-toggle mb-0 collapsed"
                  data-toggle="collapse"
                  data-parent="#accordion-co2"
                  href="#collapse-co2"
                  aria-expanded="false">Taux de co2</a>
              </h4>
            </div>
            <div id="collapse-co2"
              className="panel-collapse collapse show"
              role="tabpanel"
              aria-expanded="false">
              <div className="panel-body" style={{padding: 0}}>
                <div className="card mb-0">
                  <div className="card-body" style={{height: 425}}>
                    {
                      <LineChart
                        yAxisUnit='Concentration de CO2 (ppm)'
                        data={energies || []}
                        XKey="ts"
                        YKey="co2"
                        lookupLabels={labels}>
                      </LineChart>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        !hasCo2 && !hasTemperature && hasFetchData && (
          <h1>{gettext("Aucune donnée disponible pour ce capteur")}</h1>
        )
      }
    </>
  );
}

