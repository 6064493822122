import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface Props {
  data: any;
  color: string;
  width?: number;
  height?: number;
  setShowText?: (value: boolean) => void;
}

export default function StyledBarChart({data, color, width, height, setShowText}: Props) {
  return (
    <ResponsiveContainer width="100%" height={275}>
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: -20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis unit={"kW"} />
        <Tooltip />
        <Legend />
        <Bar dataKey="Puissance Atteinte" fill={color} onAnimationEnd={() => setShowText && setShowText(true)}/>
      </BarChart>
    </ResponsiveContainer>
  );
}
