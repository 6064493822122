import React from "react"

export default function TableCostic({ month, month_data }: { month: string, month_data: any }) {
  return (
    <div className="col-xl-12">
      <div className="card text-center">
        <div className="card-body">
          <h1>{month}</h1>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Day</th>
                  {Object.keys((month_data[Object.keys(month_data)[0]])).map((day) => {
                    return (
                      <React.Fragment key={day}>
                        <th scope="col" key={day}>{day}</th>
                      </React.Fragment>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.entries(month_data).map(([city, dju_data]) => {
                  return (
                    <tr key={city}>
                      <th scope="row">{city}</th>
                      {Object.values(dju_data).map((dju) => {return (<td>{dju}</td>)})}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  )
}