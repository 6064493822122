import translation from '@src/common/locale/translation';
import { RootState } from '@src/context/store';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Average } from '../data/sensor-analysis';

interface Props {
  data: Average[]
}

const getPeriodStep = (timestamp: number, timestamp2: number) => {
  const date = moment(timestamp);
  const date2 = moment(timestamp2);
  const diffInDay = Math.abs(date.diff(date2, 'days')) 
  return diffInDay <= 15 ? "daily" : "monthly";
}


export default function TemperatureAverageChart({data}: Props) {

  const store = useSelector(
    (state: RootState) => state.dashboard.analysis.filter
  );

    const lang = document.documentElement.lang;
    const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit' };

    const periodStep = getPeriodStep(store.period.start, store.period.end);
    
    if (periodStep == "daily") {
      dateOptions["day"] = "numeric";
    }
    data?.forEach((d:any) => d.name = new Date(d.name * 1000).toLocaleDateString(lang, dateOptions));    

    const translateDataKeys = (averages: Average[]) => {
      if (!averages || averages.length <= 0) return [];
      const keys = Object.keys(averages[0]);
      return averages.map(average => {
        const data: {[key: string]: any} = {};
        keys.forEach(key => {
            const k = translation[key];
            data[k] = average[key];
        })
        return data;
      });
    }
  
    
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={translateDataKeys(data)}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey={translation["name"]} />
          <YAxis unit="°C" />
          <Tooltip formatter={(value: number) => Math.round(value * 10) / 10} />
          <Legend />
          <Bar dataKey={translation["occupied"]} barSize={20} fill="#8884d8" unit="°C"/>
          <Bar dataKey={translation["unoccupied"]} barSize={20} fill="#82ca9d" unit="°C" />
          <Bar dataKey={translation["weekend"]} barSize={20} fill="#891296" unit="°C" />
          {/* <Line type="monotone" dataKey="Temperature Exterieur" stroke="#ff7300" /> */}
        </ComposedChart>
      </ResponsiveContainer>
    );
}

