import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { tableDisplaying as storeDisplaying } from "@src/context/reducers/dashboard";
import { RootState } from "@src/context/store";
import { useDispatch, useSelector } from "react-redux";

export default function ToggleData() {
  const displaying = useSelector((state: RootState) => state.dashboard.table.displaying);
  
  const dispatch = useDispatch();

  const handleDisplay = (_: any, newDisplay: string | null) => {
    dispatch(storeDisplaying(newDisplay));
  };

  return (
    <div className="text-center">
      <ToggleButtonGroup
        value={displaying}
        exclusive
        onChange={handleDisplay}
        aria-label="Select data type"
      >
        <ToggleButton id="table-temp-button" value="temperature" aria-label="Data Field Temperature">
          <i className="fa-solid fa-temperature-half"></i> {gettext("Température")}
        </ToggleButton>
        <ToggleButton id="table-co2-button" value="co2" aria-label="Data Field Co2">
          <i className="fa-solid fa-smog"></i> CO<sub>2</sub>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}