import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import Loading from "@src/common/components/is-loading";
import mapping from "@src/common/mapping";
import { RootState } from "@src/context/store";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SensorTableBody from "./sensor-table-body";
import {updateURL} from "@src/common/utils";


interface TableData {
  ts: number;
  temperature: number;
  co2: number;
  [key: string]: any;
}
export interface TableResponse {
  current: TableData;
  previous: TableData;
  label?: string;
  deveui?: string;
}

export interface DashboardTableResponse {
  [key: string]: TableResponse[];
}

export default function SensorTable() {
  const [params] = useSearchParams();
  const [data, setData] = useState<DashboardTableResponse>();
  const [dataFiltered, setDataFiltered] = useState<DashboardTableResponse>();
  const [floorKeys, setFloorKeys] = useState<string[]>([]);
  const [zoneKeys, setZonesKeys] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const store = useSelector((state: RootState) => state.dashboard.table.filter);
  const displaying = useSelector((state: RootState) => state.dashboard.table.displaying);
  const errorMessage = useSelector((state: RootState) => state.dashboard.table.error);

  const fetchSensors = async () => {
    if (store.equipments.length) {
      setIsLoading(true);
      axios
      .get(
        `/api/v1/comfort/dashboard/table?${params}&deveui="${store.equipments?.map(sensor => sensor.name).join(',')}"`
      )
      .then((res) => {
        setData(res.data);
        setDataFiltered(res.data);
        const [floors, zones] = extractKeys(res.data);
        setFloorKeys(floors);
        setZonesKeys(zones);
      })
      .finally(() => setIsLoading(false));
    } else {
      setData({})
    }

  }

  useEffect(() => {
    fetchSensors().then();

    const fiveMinute = 5 * 60 * 1_000;
    const intervalID = setInterval(
        async () => {
            await fetchSensors();
        },
        fiveMinute
    );
    return () => {
        clearInterval(intervalID);
    }
  }, [store.equipments]);

  useEffect(() => {
    if (Object.keys(data || {}).length === 0) return;
    setDataFiltered(_.cloneDeep(data));
  }, [data, displaying])

  const getItems = (data_: DashboardTableResponse, floor: string, zone: string): TableResponse[] => {
    const key = `${floor} - ${zone}`;
    return data_[key] ? data_[key].map((item: any) => item) : [];
  };

  const extractKeys = (data_: DashboardTableResponse) => {
    const zoneKeys = new Set<string>();
    const floorKeys = new Set<string>();
    for (const key in data_) {
      const [floor, zone] = key.split(' - ');
      
      const items = getItems(data_, floor, zone);
      if (items.length) {
        floorKeys.add(floor);
        zoneKeys.add(zone);
      }
      
    }
    return [Array.from(floorKeys), Array.from(zoneKeys)];
  };

  if (!!errorMessage) {
    return  <h2 className="text-center text-danger mt-5">{errorMessage}</h2>
  }

  if (isLoading) {
    return <Loading />;
  }
  if (!data || Object.keys(data).length === 0) {
    return  <h2 className="text-center mt-5">{gettext("Aucune donnée disponible.")}</h2>
  }

  return (
      <TableContainer component={Paper} id="sensor-table-data"> 
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <span>Zone <i className="fa-solid fa-arrow-right"></i></span>
                <br/>
                <span>Étage <i className="fa-solid fa-arrow-down"></i></span>
              </TableCell>
              {zoneKeys.sort().map((key: string, index: number) => (
                <TableCell align="center" key={key+index}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>    
          {
            <SensorTableBody data={dataFiltered} floors={floorKeys} zones={zoneKeys} />
          }
        </Table>
      </TableContainer>
  );
  }
