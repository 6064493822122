import { Fragment } from "react";
import { Card } from "./collapse-card";
import Generic from "./generic";

export default function Energy() {
  const ELEC_HEADER_ITEM_HEIGHT = 470;
  const PERIOD_MENU_HEIGHT = 100;
  const charts = [
    {
      eng: "elec",
      title: gettext("Electricity"),
      id: "line-elec",
      height: 400 + PERIOD_MENU_HEIGHT + ELEC_HEADER_ITEM_HEIGHT,
      children: () => <Generic eng="elec" />,
    },
    {
      eng: "hot",
      title: gettext("Heating network"),
      id: "line-hot",
      height: 365 + PERIOD_MENU_HEIGHT + ELEC_HEADER_ITEM_HEIGHT,
      children: () => <Generic eng="hot" />,
    },
    {
      eng: "cold",
      title: gettext("Cold network"),
      id: "line-cold",
      height: 365 + PERIOD_MENU_HEIGHT + ELEC_HEADER_ITEM_HEIGHT,
      children: () => <Generic eng="cold" />,
    },
    {
      eng: "capt",
      title: gettext("CVC systems sensors"),
      id: "line-temp-co2",
      height: 400 + PERIOD_MENU_HEIGHT,
      children: () => <Generic eng="capt" />,
    },
  ];

  return (
    <Fragment>
      {charts?.map(({ children, ...def }) => {
        const render = children();
        if (render) {
          return (
            <Card
              cardBodyStyle={{ height: def.height }}
              key={def.id}
              {...def}
            >
              {children()}
            </Card>
          );
        }
      })}
    </Fragment>
  );
}
