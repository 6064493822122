import { RootState } from "@src/context/store"
import { useSelector } from "react-redux"
import TableCostic from "./costicTable"
import UploadBox from "./uploadBox"

export default function CosticForm() {
    const data = useSelector<RootState>((RootState) => RootState.costic.data)
    return (
        <div className="container-fluid">

            {<div className="row">
                <UploadBox />
            </div>}
            {data ? Object.entries(data).map(([month, month_data]) => {
                return (
                    <div className="row" key={month}>
                    <TableCostic month={month} month_data={month_data}/>
                    </div>)
                }): ""}
        </div>
    )
}