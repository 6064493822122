import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { dashboardReducer, metrologyReducer } from './reducers';
import costic from './reducers/costic';

export const store = configureStore({
  reducer: combineReducers({
    metrology: metrologyReducer,
    dashboard: dashboardReducer,
    costic: costic
  })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ReturnType<typeof store.dispatch>