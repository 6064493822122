import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'dropify/src/sass/dropify.scss';
import './plugins/feather/feather.css';
import './scss/style.scss';
import './scss/lib/valex/sidemenu.scss';
import './scss/lib/valex/skin-modes.scss';

import './css/animate.css';
import './css/select2.css';
import './css/sidebar.css';

import './components/custom';
import './components/left-menu';
import './components/notify';
import './components/sidebar';

import {initSideMenu} from './components/sidemenu';

$(function () {
    import('dropify').then(() => $('.dropify').dropify());


    Vue.component('treeselect', VueTreeselect.Treeselect)

    const search = window.location.search;
    const params = new URLSearchParams(search)
    const parc = params.get('parc')
    const building = params.get('building')

    initSideMenu()
    new Vue({
        el: '#app',
        autoSelectAncestors: true,
        data: {
            // define the default value
            value: parc && `parc=${parc}${building ? `&building=${building}` : ''}`,
            // define options
            options: null,
        },
        mounted: function () {
            $.ajax({
                url: '/yolo',
                method: 'GET',
                success: (data) => {
                    this.options = data;
                },
                error: (error) => {
                    console.log(error);
                }
            });
        },
        methods: {
            onInput(value) {
                if (value) {
                    const newParams = new URLSearchParams(value);

                    params.set('parc', newParams.get('parc'));

                    if (newParams.has('building')) {
                        params.set('building', newParams.get('building'));
                    } else {
                        params.delete('building')
                    }

                    return window.location.search = params;
                }
                return value
            }
        }
    });


});
