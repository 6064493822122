import { Switch } from "@mui/material";
import _ from "lodash";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { thresholds as storeThresholds } from "../../../context/reducers/metrology";
import { RootState } from "../../../context/store";

const translations = {
  winter: gettext("Winter"),
  summer: gettext("Summer"),
  annual: gettext("Yearly"),
  luminosity: gettext("Yearly"),
};

export default function Thresholds() {
  const thresholds = useSelector(
    ({ metrology: { thresholds } }: RootState) => thresholds
  );
  const dispatch = useDispatch();

  const {temp, luminosity, humidity, amb} = thresholds;

  const categories = {
    temp: {
      set: (value: typeof temp) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {temp: value})))
      },
      values: _.cloneDeep(temp),
      translate: gettext("Temperature"),
    },
    humidity: {
      set: (value: typeof humidity) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {humidity: value})))
      },
      values: _.cloneDeep(humidity),
      translate: gettext("Humidity"),
    },
    amb: {
      set: (value: typeof amb) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {amb: value})))
      },
      values: _.cloneDeep(amb),
      translate: gettext("Co2 Rate"),
    },
    luminosity: {
      set: (value: typeof luminosity) => {
        dispatch(storeThresholds(Object.assign({}, thresholds, {luminosity: value})))
      },
      values: _.cloneDeep(luminosity),
      translate: gettext("Luminosity"),
    },
  };

  return (
    <div className="panel panel-default mt-3 mb-0">
      <div
        className="panel-heading1"
        css="text-align: center;
                      background-color: #242f48;
                      border-radius: 6px"
      >
        <h4 className="panel-title1">
          <a
            className="accordion-toggle mb-0 collapsed"
            data-toggle="collapse"
            data-parent="#filterMetrologie"
            href="#thresholds"
            aria-expanded="false"
          >
            {gettext("Thresholds")}
          </a>
        </h4>
      </div>
      <div
        id="thresholds"
        className="panel-collapse collapse"
        role="tabpanel"
        aria-expanded="false"
        css=""
        data-category="{{ category }}"
      >
        <div className="panel-body" css="padding: 0;">
          <div className="card-body" css="padding: .7rem;">
            <table className="table table-borderless table-sm">
              <thead>
                <tr>
                  <th>{gettext("Period")}</th>
                  <th>{gettext("Min")}</th>
                  <th>{gettext("Max")}</th>
                  <th>{gettext("Activate")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(categories).map(([c, category]) => (
                  <Fragment key={c}>
                    <tr>
                      <td colSpan={5} style={{ textAlign: "center" }}>
                        {category.translate}
                      </td>
                    </tr>
                    {Object.entries(category.values).map(([k, v]) => {
                      return (
                        <tr key={`${c}-${k}`}>
                          <td>
                            {
                              translations[
                                k as keyof typeof category.values
                              ] as string
                            }
                          </td>
                          <td>
                            <input
                              defaultValue={v.min}
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                const temp = Object.assign(
                                  {},
                                  category.values
                                ) as any;
                                temp[k].min = parseInt(e.target.value);
                                category.set(temp);
                              }}
                              type="number"
                              step={1}
                              min={0}
                            />
                          </td>
                          <td>
                            <input
                              defaultValue={v.max}
                              className="form-control form-control-sm"
                              onChange={(e) => {
                                const temp = Object.assign(
                                  {},
                                  category.values
                                ) as any;
                                temp[k].max = parseInt(e.target.value);
                                category.set(temp);
                              }}
                              type="number"
                              step={1}
                              min={0}
                            />
                          </td>
                          <td>
                            <Switch
                              onChange={() => {
                                const temp = Object.assign(
                                  {},
                                  category.values
                                ) as any;
                                temp[k].active = !temp[k].active;
                                category.set(temp);
                              }}
                              checked={
                                !!(category.values as any)[k]?.active as boolean
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  setThresholds(thresholds: any): void;
}
